import React                from 'react';
import Dialog               from '@material-ui/core/Dialog';
import {withSnackbar}       from 'notistack';
import DialogTitle          from '@material-ui/core/DialogTitle';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogActions        from '@material-ui/core/DialogActions';
import Typography           from '@material-ui/core/Typography';
import Grid                 from '@material-ui/core/Grid';
import Slider               from '@material-ui/core/Slider';
import Button               from '@material-ui/core/Button';
import IconButton           from '@material-ui/core/IconButton';
import TextField            from '@material-ui/core/TextField';
import ButtonGroup          from '@material-ui/core/ButtonGroup';
import Fail                 from '@material-ui/icons/Clear';
import Loader               from 'components/atoms/Loader';
import apis                 from 'common/apis';
import Log                  from 'common/log';
import {errorMsg}           from 'common/errors';
import utility              from 'common/utility';
import authStore            from 'redux/authStore';
import {styles}             from './style';

const log = Log ('RatingModal', 'info');

class RatingModal extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			japanese_level    : 0,
			skill_match       : 0,
			personality_match : 0,
			interview_result  : null,
			interview_note    : '',
			applicant         : null,
			submitLoading     : false,
			errors            : {},
		};
		this.notify = this.props.enqueueSnackbar;
		this.result = ["〇","△","×"];
	}

	componentDidMount = () => {
		let modalData = this.props.modalData;
		let interview = modalData.interview;
		let applicant = modalData.applicant;
		let resultIndex = this.result.findIndex (item => item === interview.result);
		this.setState ({
			applicant,
			japanese_level    : interview.japanese_level || 0,
			skill_match       : interview.skill_match || 0,
			personality_match : interview.personality_match || 0,
			interview_result  : resultIndex > -1 ? resultIndex : null,
			interview_note    : interview.note || '',
		});
	}

	handleResultClick = (value) => {
		
		this.setState ({
			interview_result : value,
		});
	}

	handleSlider = (event, newValue, name) => {
	
		this.setState ({
			[name] : newValue,
		});
	}

	handleTextField = (event) => {
		
		const {name, value} = event.currentTarget;
		this.setState ({
			[name] : value
		});
	}

	checkValidSubmission = () => {
		let __errors = {};
		if (!this.state.japanese_level) {
			__errors['japanese_level'] = true;
		}
		if (!this.state.skill_match) {
			__errors['skill_match'] = true;
		}
		if (!this.state.personality_match) {
			__errors['personality_match'] = true;
		}
		if (this.state.interview_result === null) {
			__errors['interview_result'] = true;
		}
		if (!this.state.interview_note || this.state.interview_note.trim() === ""){
			__errors['interview_note'] = true;
		}
		if (!Object.keys(__errors).length) {
			return true;
		}
		this.setState({
			errors : {
				...__errors
			}
		});
		return false;
	}

	onSubmitRating = async () => {

		let isValidSubmission = this.checkValidSubmission();

		if (!isValidSubmission) {
			this.notify('必須フィールドをすべて入力してください', { variant: 'error' });
			return;
		}

		let modalData = this.props.modalData;
		let authInfo = authStore.getState();
		let picProfile = authInfo.picProfile;
		
		let data = {
			interview : {
				id : this.props.interviewId,
				interview_reviews_attributes : [{
					resource_id       : picProfile.id,
					resource_type     : 'CompanyPic',
					japanese_level    : this.state.japanese_level || null,
					skill_match       : this.state.skill_match || null,
					personality_match : this.state.personality_match || null,
					interview_result  : this.state.interview_result === null ? 3 : this.state.interview_result,
					interview_note    : this.state.interview_note,
					is_final          : modalData.isFinal ? "1" : "0",
					id : "",
				}],
			}
		};

		this.setState ({
			submitLoading : true,
		});

		let result;

		try {
			result = await apis.submitRating(data);
		}
		catch (err) {
			log.error ({err}, 'error submiting data');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				submitLoading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		log.info ({ratings : result}, 'rating submitted ok');
		this.setState ({
			submitLoading : false
		});
		this.props.close(true);
	}

	render () {

		const modalData = this.props.modalData;

		return (
			<Dialog open = {true} maxWidth = 'sm' fullWidth = {true} onBackdropClick = {() => this.props.close(false)}>
				<DialogTitle disableTypography>
					<Typography variant = 'h6' style = {{fontWeight : 'bold'}}> {this.state.applicant ? this.state.applicant.name : ''} さんの評価はいかがですか？ </Typography>
					<IconButton size = 'small' style = {styles.closeButton} onClick = {() => this.props.close(false)}> <Fail/></IconButton>
				</DialogTitle>
				<DialogContent>
					<Grid>
						<Typography variant = 'subtitle1'> 日本語力 (1 = 低, 5= 高) <span style = {{color : '#C33934'}}>*</span> </Typography>
						<Slider
							value             = {this.state.japanese_level}
							onChange          = {(e, v) => this.handleSlider(e, v, 'japanese_level')}
							valueLabelDisplay = "auto"
							step              = {1}
							min               = {0}
							max               = {5}
							marks             = {true}
						/>
					</Grid>
					<Grid className = 'mt-8'>
						<Typography variant = 'subtitle1'> スキルマッチ (1 = 低, 5= 高) <span style = {{color : '#C33934'}}>*</span> </Typography>
						<Slider
							value             = {this.state.skill_match}
							onChange          = {(e, v) => this.handleSlider(e, v, 'skill_match')}
							defaultValue      = {0}
							valueLabelDisplay = "auto"
							step              = {1}
							min               = {0}
							max               = {5}
							marks             = {true}
						/>
					</Grid>
					<Grid className = 'mt-8'>
						<Typography variant = 'subtitle1'> 人物マッチ (1 = 低, 5= 高) <span style = {{color : '#C33934'}}>*</span></Typography>
						<Slider
							value             = {this.state.personality_match}
							onChange          = {(e, v) => this.handleSlider(e, v, 'personality_match')}
							defaultValue      = {0}
							valueLabelDisplay = "auto"
							step              = {1}
							min               = {0}
							max               = {5}
							marks             = {true}
						/>
					</Grid>
					<Grid className = 'mt-8'>
						<Typography variant = 'subtitle1'> 合否 <span style = {{color : '#C33934'}}>*</span>  </Typography>
						<ButtonGroup fullWidth variant = 'outlined'>
							<Button style = {this.state.interview_result !== 0 ? styles.btnUnselcted : styles.btnSelected} onClick = {() => this.handleResultClick (0)}>
								合格
							</Button>
							<Button style = {this.state.interview_result !== 1 ? styles.btnUnselcted : styles.btnSelected} onClick = {() => this.handleResultClick (1)}>
								保留
							</Button>
							<Button style = {this.state.interview_result !== 2 ? styles.btnUnselcted : styles.btnSelected} onClick = {() => this.handleResultClick (2)}>
								不合格
							</Button>
						</ButtonGroup>
					</Grid>
					<Grid className = 'mt-8'>
						<Typography variant = 'subtitle1'> コメント <span style = {{color : '#C33934'}}>*</span> </Typography>
						<TextField
							onChange    = {this.handleTextField}
							placeholder = 'コメント'
							multiline   = {true}
							rows        = {3}
							rowsMax     = {6}
							fullWidth   = {true}
							variant     = 'outlined'
							value       = {this.state.interview_note}
							name        = "interview_note"
							error       = {this.state.errors.interview_note}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant = {modalData.isFinal ? 'contained' : 'outlined'} color = 'primary' onClick = {this.onSubmitRating}>
						{this.state.submitLoading ? <Loader color = 'primary'/> : modalData.isFinal ? '最終評価を入力' : '一次評価を入力'}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withSnackbar(RatingModal);
