import React                            from 'react';
import moment                           from 'moment';
import {withSnackbar}                   from 'notistack';
import { MuiPickersUtilsProvider }      from '@material-ui/pickers';
import { DatePicker }                   from "@material-ui/pickers";
import MomentUtils                      from '@date-io/moment';
import Grid                             from '@material-ui/core/Grid';
import Select                           from '@material-ui/core/Select';
import MenuItem                         from '@material-ui/core/MenuItem';
import Button                           from '@material-ui/core/Button';
import Paper                            from '@material-ui/core/Paper';
import Typography                       from '@material-ui/core/Typography';
import Hidden                           from '@material-ui/core/Hidden';
import Tabs                             from '@material-ui/core/Tabs';
import Tab                              from '@material-ui/core/Tab';
import IconButton                       from '@material-ui/core/IconButton';
import ClearIcon                        from "@material-ui/icons/Clear";

import TopNav                           from 'components/molecules/TopNav';
import MobileNav                        from 'components/molecules/MobileNav';
import ApplicantCard                    from 'components/molecules/ApplicantCard';
import authStore, {picProfileInfo}      from 'redux/authStore';
import apis                             from 'common/apis';
import Log                              from 'common/log';
import {errorMsg}                       from 'common/errors';
import utility                          from 'common/utility';
import Skeleton                         from './Skeleton';
import RatingModal                      from './RatingModal';

const log = Log ('Interview', 'info');

class Interview extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav     : false,
			loading       : false,
			picProfile    : null,
			cart          : [],
			selectedDay   : null,
			scheduled     : null,
			allScheduled  : [],
			applicants    : [],
			ratingModal   : false,
			modal_id      : '',
			events        : [],
			selectedDate  : null,
			selectedEvent : '',
			steps         : [],
			selectedSteps : 'ALL',
			jobId         : 0,
			modalData     : {
				interview : null,
				applicant : null,
				isFinal   : false,
			}
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		
		this.getProfile ();
		this.getCart ();
		this.getScheduledInterview (true);
	}

	getCart = async () => {

		let result;

		try {
			result = await apis.getCart ();
			log.info ({result}, 'cart info get ok');
		}

		catch (err) {
			log.error ({err}, 'error getting cart info');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		this.setState ({
			cart : result.cart
		});
	}

	handleDateChange = (date) => {
		if(!date) {
			return;
		}

		this.setState ({
			selectedDay : date._d,
		});
	}

	clearDate = () => {
	
		this.setState ({
			selectedDay : null,
		});
	}

	handleEventChange = (event) => {
		const {value} = event.target.value;
		let sgwj_event = this.state.scheduled.find (e => e.event_id === value);
		if (!sgwj_event) {
			return;
		}
		this.setState ({
			selectedEvent : value,
			scheduled : sgwj_event,
		});
	}

	handleStepChange = (event) => {
		const { value } = event.target;
		this.setState ({
			selectedSteps : value
		});
	}

	openRatingModal = (interviewId, applicantId, is_final) => {
		
		/*
		let __event = this.state.allScheduled.find (e => e.event_id === this.state.selectedEvent);
		if (!__event) {
			return;
		}
		*/
		let __allScheduled = this.state.allScheduled;
		let __applicant;
		for (const ev of __allScheduled) {
			let __applicants = ev.applicants;
			let _applicant = __applicants.find(a => a.id === applicantId);
			if (_applicant) {
				__applicant = _applicant;
				break; // Break out of the loop when the applicant is found
			}
		}
		if (!__applicant) {
			return;
		}
		let __interview = __applicant.interviews.find (i => i.id === interviewId);

		if (!__interview) {
			return;
		}

		this.setState ({
			ratingModal : true,
			modal_id    : interviewId,
			modalData   : {
				interview : __interview,
				applicant : __applicant,
				isFinal   : is_final,
			}
		});
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	closeRatingModal = (fetchData) => {
		
		this.setState ({
			ratingModal : false,
			modal_id    : "",
			modalData   : {
				interview : null,
				applicant : null,
			}
		});
		if (!fetchData) {
			return;
		}
		this.getScheduledInterview(false);
	}

	getProfile = async () => {

		let authInfo   = authStore.getState ();
		let picProfile = authInfo.picProfile;

		if (!picProfile) {
			try {
				picProfile = await apis.getProfile ();
			}
			catch (err) {
				log.error ({err}, 'error getting company pic profile');
				if (err.response && err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
				return;
			}
			log.info ({pic : picProfile}, 'get pic profile');

			let jobId = picProfile.jobs[0].job_id;
			this.setState ({
				picProfile,
				jobId,
			});
			authStore.dispatch (picProfileInfo (picProfile));
		}
		let jobId = picProfile.jobs[0] ? picProfile.jobs[0].job_id : '';
		this.setState ({
			picProfile,
			jobId,
		});
	}

	getScheduledInterview = async (loading) => {
		
		if (loading) {
			this.setState ({
				loading,
			});
		}

		let scheduled;

		try {
			scheduled = await apis.interviewScheduled ();
		}
		catch (err) {
			log.error ({err}, 'error getting scheduled interview');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			this.notify ('予期しないエラーが発生しました、もう一度お試しいただくか、担当営業までお問い合わせください。', {variant : 'error'});
			return;
		}
		log.info ({scheduled}, 'scheduled interview get ok');

		if (!scheduled.length) {
			this.setState ({
				loading : false,
			});
			return;
		}

		let events = scheduled.map (ev => {
			return {
				event_id : ev.event_id,
				event_name : ev.event_name,
			};
		});

		let steps = [];

		let __scheduled = []; //defualt showing the scheduled interview of first event
		for (let i = 0; i < scheduled.length; i++) {
			for (let j = 0; j < scheduled[i].applicants.length; j++) {
				__scheduled.push ({
					event_name : scheduled[i].event_name,
					event_id : scheduled[i].event_id,
					...scheduled[i].applicants[j],
				});
				steps = [...steps, ...scheduled[i].applicants[j].interviews?.map(intv => intv.step)];
			}
		}

		steps = [...new Set(steps)];

		this.setState ({
			allScheduled : scheduled,
			scheduled : __scheduled,
			events,
			loading : false,
			selectedEvent : scheduled[0].event_id,
			steps
		});
	}

	handleDownloadCsv = () => {
		utility.downloadApplicants (this.state.scheduled, 'INTERVIEW');
	}

	handleDownloadNotes = () => {
		utility.downloadInterviews(this.state.scheduled);
	}

	onJobChange = (event, newValue) => {
		let jobId = newValue;
		this.setState ({
			jobId,
		});
	}

	renderApplicants = () => {
		
		let applicants = this.state.scheduled;
		let interviewsCards = [];
		
		for (let i = 0; i < applicants.length; i++) {
			/*
			if (Array.isArray(applicants[i].interviews) && !applicants[i].interviews.length) {
				continue; //filtering those applicants which has no interview scheduled;
			}
			*/

			/**commenting for now, seems the logic is incorrect, need to check */
			// if (!this.state.selectedDay) {
			// 	interviewsCards.push (
			// 		<ApplicantCard page = 'INTERVIEW' applicant = {applicants[i]} scheduledInterview = {applicants[i].interviews} key = {applicants[i].id} openRatingModal = {this.openRatingModal}/>
			// 	);
			// 	continue;
			// }

			let __interviews = [];
			for (let j = 0; j < applicants[i].interviews.length; j++) {
				let __interview= applicants[i].interviews[j];

				/**Filter by steps */
				if (this.state.selectedSteps !== 'ALL') {
					let hasSelectedStep = applicants[i].interviews?.find(intv => intv.step === this.state.selectedSteps);
					let hasOtherSelectedStep = applicants[i].interviews?.find(intv => intv.step !== this.state.selectedSteps);
					if (!hasSelectedStep || hasOtherSelectedStep) {
						continue;
					}
				}

				/**Filter by job name in the tab */
				if (__interview.job_id !== this.state.jobId) {
					continue;
				}

				/**Filter by selected day */
				if (this.state.selectedDay && moment (__interview.start_time).format ('DD/MM/YY') !== moment (this.state.selectedDay).format ('DD/MM/YY')) {
					continue;
				}
				__interviews.push (applicants[i].interviews[j]);
			}

			if (!__interviews.length) {
				continue;
			}

			interviewsCards.push (
				<ApplicantCard page = 'INTERVIEW' applicant = {applicants[i]} scheduledInterview = {__interviews} key = {applicants[i].id} openRatingModal = {this.openRatingModal}/>
			);
		}

		if (interviewsCards.length) {
			return interviewsCards;
		}

		if (this.state.selectedDay) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> 選択した日付に面接はありませんでした。</Typography>
			);
		}

		return (
			<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> 面接はありません。 </Typography>
		);
	}

	render () {

		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav} name = {this.state.picProfile ? this.state.picProfile.name : ''} cartNumber = {this.state.cart.length}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid container spacing = {3}>
						<Grid item lg = {1} xl = {2}>
						</Grid>
						<Grid item lg = {10} xl = {8} md = {12} sm = {12} xs = {12}>
							<Paper elevation = {2} className = 'p-16'>
								<Grid container spacing = {2} alignItems = 'center'>
									<Grid item sm = {2} md = {2} lg = {2} xl = {2}>
										<Typography variant = 'h4'> 面接予定 </Typography>
									</Grid>
									<Grid item sm = {10} md = {10} lg = {10} xl = {10} container justify = 'flex-end' style={{gap : 24}} alignItems='flex-end'>
										<Button disabled = {!this.state.scheduled?.length} color = 'primary' variant = 'contained' onClick = {this.handleDownloadNotes}>
											面接評価をダウンロード
										</Button>
										<Button disabled = {!this.state.scheduled?.length} color = 'primary' variant = 'contained' onClick = {this.handleDownloadCsv}>
											学生情報をダウンロード
										</Button>
										{/*
										<Select value = {this.state.selectedEvent} variant = 'outlined' onChange = {this.handleEventChange}>
											{this.state.events.map (e => (
											<MenuItem value = {e.event_id} key = {e.event_id}> {e.event_name}</MenuItem>
											))}
										</Select>
										*/}
										<MuiPickersUtilsProvider utils = {MomentUtils}>
											<DatePicker
												autoOk
												label = '日付の選択'
												views={["year", "month", "date"]}
												value={this.state.selectedDay}
												onChange={this.handleDateChange}
											/>
										</MuiPickersUtilsProvider>
										{this.state.selectedDay ?
											<IconButton size = 'small' color = 'primary' style = {{position : 'relative', top : '8px'}} onClick = {this.clearDate}> <ClearIcon /> </IconButton>
											: null}
									</Grid>
								</Grid>
							</Paper>
							{this.state.jobId &&
								<Paper className = 'p-12 mt-12' variant = 'outlined'>
									<Grid container spacing={2}>
										<Grid item sm = {8} md = {9} lg = {9} xl = {9}>
											<Tabs value = {this.state.jobId} textColor = 'inherit' onChange = {this.onJobChange} variant="scrollable" scrollButtons="auto">
												{this.state.picProfile?.jobs?.length ? this.state.picProfile?.jobs.map (item => (
													<Tab key = {item.job_id} value = {item.job_id} label = {item.job_title}/>
												)) :
													<Tab key = {'0'} value = {null} label = {'All'}/>
												}
											</Tabs>
										</Grid>
										<Grid item sm = {4} md = {3} lg = {3} xl = {3} container justify='flex-end' alignItems='center'>
											<Typography variant='subtitle1'>  選考ステップ： </Typography>
											<Select value = {this.state.selectedSteps} variant = 'outlined' onChange = {this.handleStepChange}>
												<MenuItem value= 'ALL'> すべて表示</MenuItem>
												{this.state.steps.map (stepName => (
													<MenuItem value = {stepName} key = {stepName}> {stepName}</MenuItem>
												))}
											</Select>
										</Grid>
									</Grid>
								</Paper>
							}
							{this.state.loading ? <Skeleton/> : this.state.scheduled ? this.renderApplicants () :
								<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '96px'}}> 面接はありませんでした。 </Typography>
							}
							{this.state.ratingModal ? <RatingModal close = {this.closeRatingModal} interviewId = {this.state.modal_id} modalData = {this.state.modalData}/> : null}
						</Grid>
						<Grid item lg = {1} xl = {2}>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Interview);
